//import logo from "./logo.svg";
import {
  AppstoreOutlined,
  CarOutlined,
  MenuOutlined,
  UsergroupAddOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Button, Layout, Menu } from "antd";
import React, { useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import "./App.css";
// Import the functions you need from the SDKs you need
// Roadside Assist WebApp

import { Typography } from "antd";
import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import { resetState, updateAuthStatus } from "./store/reducers/authReducer";
// import logo from "../src/assets/images/Elog Book LOGO-02.jpeg";
import { Toaster } from "react-hot-toast";
import logo from "../src/assets/images/white-.png";
import { appVersion } from "./store/utils/APIUtils";
const { Header, Content, Footer, Sider } = Layout;

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

const firebaseConfig = {
  apiKey: "AIzaSyDzrjlxiP450JFJ0Al0rkl3e0XYWSDHWvA",
  authDomain: "picobyte-solutions.firebaseapp.com",
  projectId: "picobyte-solutions",
  storageBucket: "picobyte-solutions.appspot.com",
  messagingSenderId: "992029135546",
  appId: "1:992029135546:web:5fc9263da0474e67a46883",
  measurementId: "G-CX7QCT7G4P",
};

const { Title } = Typography;

function App() {
  const navigate = useNavigate();

  const isMobile = window.innerWidth < 768;

  let state = useSelector((state) => state);
  const [collapsed, setCollapsed] = useState(false);

  const dispatch = useDispatch();
  const currentPath = useLocation();
  // const [is_authenticated, setAuthenticated] = useState(false);
  const access_token = useSelector((state) => state?.persist?.auth?.token);
  const { is_authenticated, isSuperAdmin } = useSelector(
    (state) => state?.persist?.auth
  );

  useEffect(() => {
    // Initialize Firebase only if it hasn't been initialized
    const app = initializeApp(firebaseConfig);
    getAnalytics(app); // No need to store it unless used
    const auth = getAuth(app);

    console.log("Firebase Auth:", auth);
    console.info("Current Route:", currentPath);

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      console.log("Auth State Changed:", user);

      if (user?.email) {
        if (access_token) {
          // User is authenticated
          if (currentPath.pathname === "/" && is_authenticated) {
            navigate("/app/dashboard");
          }
        }
      } else {
        // User is signed out
        dispatch(updateAuthStatus({ is_authenticated: false }));

        if (access_token) {
          navigate("/login");
        } else if (currentPath.pathname === "/") {
          navigate("/login");
        }
      }
    });

    // Cleanup the listener on unmount
    return () => unsubscribe();
  }, []);

  const logoutHandler = () => {
    const auth = getAuth();
    signOut(auth)
      .then(() => {
        console.log("before Reset State", state);

        dispatch({ type: "LOGOUT" });
        // dispatch(resetState());
        // dispatch(updateAuthStatus({ is_authenticated: false }));
        loginHandler();
        // window.location.reload();
      })
      .catch((error) => {
        // An error happened.
      });
    localStorage.removeItem("isDesktop");
  };

  const loginHandler = () => {
    navigate("/login");
  };

  const createAccountHandler = () => {
    navigate("/signup");
  };

  const operatorProfile = useSelector(
    (state) =>
      state?.persist?.operator?.operatorProfile?.operator?.operatorOrganization
        ?.name
  );

  return (
    <div className="App">
      {/* this is always shown in header */}

      <Layout style={{ height: "100vh", overflow: "hidden" }}>
        {/* Fixed Header (Above Sidebar) */}
        <Header
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            height: 64,
            zIndex: 1000,
            background: "#1890ff",
            padding: "0 20px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          {/* Logo & Operator Profile (Left Side) */}
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              src={logo}
              height={50}
              width={50}
              style={{ borderRadius: 8 }}
            />
            {!isSuperAdmin && (
              <Title
                type="secondary"
                level={4}
                style={{
                  color: "white",
                  paddingLeft: 15,
                  margin: 0,
                  display: is_authenticated ? "block" : "none",
                }}
              >
                {operatorProfile}
              </Title>
            )}
          </div>

          {/* Right Side - Profile & Buttons */}
          <div style={{ display: "flex", alignItems: "center", gap: "16px" }}>
            {/* Show Operator Profile at the End if Super Admin */}
            {isSuperAdmin && (
              <Title level={4} style={{ color: "white", margin: 0 }}>
                {operatorProfile}
              </Title>
            )}

            {is_authenticated && (
              <Button onClick={logoutHandler} type="danger">
                S I G N O U T
              </Button>
            )}

            {window.location.pathname !== "/login" ? (
              <div
                className="App-navitem"
                style={{
                  display:
                    window.location.pathname === "/signup" ? "flex" : "none",
                }}
              >
                <Button
                  onClick={loginHandler}
                  style={{ borderRadius: 5, color: "black" }}
                >
                  L O G I N
                </Button>
              </div>
            ) : (
              <div
                className="App-navitem"
                style={{
                  display:
                    window.location.pathname === "/login" ? "flex" : "none",
                }}
              >
                <Button
                  onClick={createAccountHandler}
                  style={{ borderRadius: 5, color: "black" }}
                >
                  S I G N U P
                </Button>
              </div>
            )}
          </div>
        </Header>

        <Layout style={{ marginTop: 64 }}>
          <Sider
            breakpoint="md"
            collapsedWidth={isMobile ? 55 : 200} // Show only icons on mobile when collapsed
            collapsible={isMobile} // Allow collapsing only on mobile
            collapsed={isMobile ? collapsed : false} // Keep expanded on desktop
            onCollapse={isMobile ? setCollapsed : undefined} // Collapse only in mobile mode
            style={{
              height: "calc(100vh - 64px)",
              position: "fixed",
              left: 0,
              top: 64,
              bottom: 0,
              zIndex: 1001,
              display: is_authenticated ? "flex" : "none",
              paddingTop: 50,
            }}
          >
            {/* Sidebar Menu */}
            <Menu
              theme="dark"
              mode="inline"
              defaultSelectedKeys={["dashboard"]}
              onClick={() => isMobile && setCollapsed(true)} // Auto-collapse on mobile only
              items={
                isSuperAdmin
                  ? [
                      {
                        key: "dashboard",
                        icon: <AppstoreOutlined />,
                        label: collapsed ? null : "Dashboard",
                        onClick: () => navigate("/app/dashboard"),
                      },
                    ]
                  : [
                      {
                        key: "dashboard",
                        icon: <AppstoreOutlined />,
                        label: collapsed ? null : "Dashboard",
                        onClick: () => navigate("/app/dashboard"),
                      },
                      {
                        key: "vehicles",
                        icon: <CarOutlined />,
                        label: collapsed ? null : "Vehicles",
                        onClick: () => navigate("/app/vehicles"),
                      },
                      {
                        key: "drivers",
                        icon: <UserOutlined />,
                        label: collapsed ? null : "Drivers",
                        onClick: () => navigate("/app/drivers"),
                      },
                      {
                        key: "users",
                        icon: <UsergroupAddOutlined />,
                        label: collapsed ? null : "Users",
                        onClick: () => navigate("/app/users"),
                      },
                    ]
              }
            />

            {/* App Version Footer (Only visible when not collapsed) */}
            {!collapsed && (
              <div
                style={{
                  position: "absolute",
                  bottom: isMobile ? 60 : 20,
                  width: "100%",
                  fontWeight: "bold",
                  textAlign: "center",
                  color: "#8C9A9B",
                  fontSize: "12px",
                }}
              >
                App Version: {appVersion}
              </div>
            )}
          </Sider>
          {/* Main Content */}
          <Layout
            style={{ marginLeft: is_authenticated && !collapsed ? 200 : 50 }}
          >
            <Content
              style={{
                // marginTop: 20,
                padding: "20px",
                minHeight: "calc(100vh - 64px)",
                overflowY: "auto",
              }}
            >
              <Toaster position="top-center" reverseOrder={false} />
              <Outlet />
            </Content>
          </Layout>
        </Layout>
      </Layout>
    </div>
  );
}

export default App;
