import {
  ArrowLeftOutlined,
  CheckCircleFilled,
  CloseCircleFilled,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Input,
  message,
  Row,
  Spin,
  Table,
  Typography,
} from "antd";
import moment from "moment";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  fetchDriverDashboard,
  fetchOrganizationDashboard,
} from "../store/reducers/adminReducer";
import UpdateOrganizationModal from "./UpdateOrganizationModal";
import { getOperatorIndustryType } from "../helpers/authAPI";

const { Title } = Typography;

const styles = {
  container: { padding: "24px", minHeight: "100vh" },
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  headerTitle: {
    display: "flex",
    alignItems: "center",
    gap: "12px",
    fontSize: "20px",
  },
  card: {
    border: "1px solid #d9d9d9",
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "70px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    backgroundColor: "#fff",
    padding: "12px",
    fontSize: "16px",
    fontWeight: "bold",
  },
  tableContainer: {
    marginTop: "20px",
    backgroundColor: "white",
    borderRadius: "8px",
    padding: "20px",
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
  },
  searchContainer: {
    display: "flex",
    gap: "12px",
    marginBottom: "16px",
    flexWrap: "wrap",
  },
  input: {
    flex: 1,
    minWidth: "250px",
    borderRadius: "6px",
  },

  buttonPrimary: {
    background: "#1890ff",
    borderColor: "#1890ff",
    color: "#fff",
    fontWeight: "bold",
    padding: "6px 12px",
  },
  billingSection: {
    textAlign: "right",
  },
  arrowStyle: {
    width: 60,
    cursor: "pointer",
    transition: "color 0.3s",
  },

  arrowHoverStyle: {
    color: "#1890ff", // Change to your desired hover color
  },
};

const OrganizationDetailsScreen = () => {
  const access_token = useSelector((state) => state?.persist?.auth.token);
  const { organizationDashboard } = useSelector(
    (state) => state?.persist?.admin
  );
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();

  const { organization, drivers } = organizationDashboard;
  const pagination = organizationDashboard?.drivers?.pagination || {};
  const [searchText, setSearchText] = useState("");
  const [extendTrial, setExtendTrial] = useState(false);
  const [updtaeOrg, setUpdateOrg] = useState(false);
  const [industryTypes, setIndustryTypes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isHovered, setIsHovered] = React.useState(false);

  const dispatch = useDispatch();
  const params = useParams();
  const { organization_id } = params;

  const handleSearch = () => {
    const value = searchText.toLowerCase();
    if (!value) {
      messageApi.open({
        type: "error",
        content: "Please enter the text ",
        className: "custom-class",
        style: {
          marginTop: "10vh",
        },
      });
      return;
    }

    setLoading(true);
    dispatch(
      fetchOrganizationDashboard({
        token: access_token,
        organization_id: organization_id,
        records_per_page: 10,
        search: value,
      })
    )
      .then((res) => {
        if (res?.payload?.message)
          messageApi.open({
            type: "error",
            content:
              res?.payload?.response?.data?.error ||
              res?.payload?.request?.data?.error ||
              res?.payload?.message,
            className: "custom-class",
            style: {
              marginTop: "10vh",
            },
          });
      })
      .catch((error) => {
        messageApi.open({
          type: "error",
          content: "Search failed. Please try again.",
          className: "custom-class",
          style: {
            marginTop: "10vh",
          },
        });
        console.error("Error searching dashboard:", error);
      })
      .finally(() => setLoading(false));
  };

  const loadDashboard = (page = 1, recordsPerPage = 10) => {
    setLoading(true);
    const value = searchText.toLowerCase();
    dispatch(
      fetchOrganizationDashboard({
        token: access_token,
        organization_id: organization_id,
        page,
        records_per_page: recordsPerPage,
        search: value,
      })
    )
      .then((res) => {
        if (res?.payload?.message)
          messageApi.open({
            type: "error",
            content:
              res?.payload?.response?.data?.error ||
              res?.payload?.request?.data?.error ||
              res?.payload?.message,
            className: "custom-class",
            style: {
              marginTop: "10vh",
            },
          });
      })
      .catch((error) => {
        messageApi.open({
          type: "error",
          content: "Failed to load dashboard.",
          className: "custom-class",
          style: {
            marginTop: "10vh",
          },
        });
        console.error("Failed to load dashboard", error);
      })
      .finally(() => setLoading(false));
  };

  const paginationConfig = {
    current: pagination?.current_page,
    pageSize: pagination?.records_per_page || 10,
    total: pagination?.num_pages * (pagination?.records_per_page || 10),
    showSizeChanger: true,
    pageSizeOptions: ["10", "20", "50", "100"],
    onShowSizeChange: (current, size) => {
      loadDashboard(current, size);
    },
    onChange: (page, pageSize) => {
      loadDashboard(page, pageSize);
    },
  };

  const handleNavigation = (item) => {
    setLoading(true);
    const fromDate = moment().subtract(7, "days").format("YYYY-MM-DD");
    const toDate = moment().format("YYYY-MM-DD");
    dispatch(
      fetchDriverDashboard({
        token: access_token,
        organization_id: organization_id,
        driver_id: item?.driverId,
        fromDate,
        toDate,
      })
    )
      .then((res) => {
        if (res?.payload?.data?.success)
          navigate(`/app/dashboard/${organization_id}/${item?.driverId}`);
        else if (res?.payload?.message)
          messageApi.open({
            type: "error",
            content:
              res?.payload?.response?.data?.error ||
              res?.payload?.request?.data?.error ||
              res?.payload?.message,
            className: "custom-class",
            style: {
              marginTop: "10vh",
            },
          });
      })
      .catch((error) => {
        messageApi.open({
          type: "error",
          content: "Error fetching Organazation Dashboard.",
          className: "custom-class",
          style: {
            marginTop: "10vh",
          },
        });
        console.error("Error fetching dashboard:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const modifyOrganization = async () => {
    try {
      setLoading(true);
      if (industryTypes?.length == 0) {
        const result = await getOperatorIndustryType();
        setIndustryTypes(result?.data);
        if (result?.success) {
          setUpdateOrg(true);
        }
      } else setUpdateOrg(true);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const HandleExtendTrial = async () => {
    try {
      setLoading(true);
      if (industryTypes?.length == 0) {
        const result = await getOperatorIndustryType();
        setIndustryTypes(result?.data);
        if (result?.success) {
          setExtendTrial(true);
        }
      } else setExtendTrial(true);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    {
      title: <span style={{ fontWeight: "bold" }}>Setup Complete?</span>,
      dataIndex: "setupCompleted",
      key: "setupCompleted",
      sorter: (a, b) =>
        a?.invitationAccepted === b?.invitationAccepted
          ? 0
          : a?.invitationAccepted
          ? -1
          : 1,
      render: (item, record) => (
        <span
          style={{
            color: item ? "#4CBB17" : "#FF6347",
            display: "flex",
            alignItems: "center",
            gap: "8px",
          }}
        >
          {item ? <CheckCircleFilled /> : <CloseCircleFilled />}
          {record?.invitationAccepted ? "Completed" : "Invitation Sent"}
        </span>
      ),
    },
    {
      title: <span style={{ fontWeight: "bold" }}>Driver Name</span>,
      dataIndex: "name",
      key: "driverName",
      sorter: (a, b) => a?.name.localeCompare(b?.name),
      render: (text) => (
        <span style={{ color: text ? "inherit" : "red" }}>{text || "N/A"}</span>
      ),
    },
    {
      title: <span style={{ fontWeight: "bold" }}>Email</span>,
      dataIndex: "email",
      key: "email",
      sorter: (a, b) => a?.email.localeCompare(b?.email),
      render: (text) => (
        <span style={{ color: text ? "inherit" : "red" }}>{text || "N/A"}</span>
      ),
    },
    {
      title: <span style={{ fontWeight: "bold" }}>Phone Number</span>,
      dataIndex: "phoneNumber",
      key: "phoneNumber",
      sorter: (a, b) => a?.phoneNumber.localeCompare(b?.phoneNumber),
      render: (text) => (
        <span style={{ color: text ? "inherit" : "red" }}>{text || "N/A"}</span>
      ),
    },
    {
      title: <span style={{ fontWeight: "bold" }}>License Type</span>,
      dataIndex: "driverType",
      key: "driverType",
      sorter: (a, b) => a?.driverType?.name.localeCompare(b?.driverType?.name),
      render: (text) => (
        <span style={{ color: text?.name ? "inherit" : "red" }}>
          {text?.name || "N/A"}
        </span>
      ),
    },
    {
      title: <span style={{ fontWeight: "bold" }}>Day Start</span>,
      dataIndex: "selectedDayStart",
      key: "selectedDayStart",
      align: "center",
      sorter: (a, b) => (a?.selectedDayStart || 0) - (b?.selectedDayStart || 0),
      render: (item) => (
        <span style={{ color: item ? "inherit" : "red" }}>
          {item
            ? moment.unix(item).utc().format("HH:mm") === "00:00"
              ? "Mid Night (00:00)"
              : "Noon (12:00)"
            : "N/A"}
        </span>
      ),
    },
    {
      title: (
        <span style={{ fontWeight: "bold" }}>Logged Work Hours (hh:mm)</span>
      ),
      dataIndex: "loggedWorkHours",
      key: "loggedWorkHours",
      align: "center",
      sorter: (a, b) =>
        (a?.loggedWorkHours?.hours || 0) * 60 +
        (a?.loggedWorkHours?.minutes || 0) -
        ((b?.loggedWorkHours?.hours || 0) * 60 +
          (b?.loggedWorkHours?.minutes || 0)),
      render: (item) => (
        <span>
          {item
            ? `${String(item?.hours).padStart(2, "0")}:${String(
                item?.minutes
              ).padStart(2, "0")}`
            : "00:00"}
        </span>
      ),
    },
    {
      title: <span style={{ fontWeight: "bold" }}>Last Log (Date)</span>,
      dataIndex: "lastLoggedAt",
      key: "lastLoggedAt",
      sorter: (a, b) =>
        a?.lastLoggedAt && b?.lastLoggedAt
          ? new Date(a?.lastLoggedAt) - new Date(b?.lastLoggedAt)
          : a?.lastLoggedAt
          ? -1
          : 1,
      render: (item) => (
        <span style={{ color: item ? "inherit" : "red" }}>
          {item ? moment(item).format("LLL") : "N/A"}
        </span>
      ),
    },
    {
      title: <span style={{ fontWeight: "bold" }}>View Logs</span>,
      dataIndex: "viewLogs",
      align: "center",
      key: "viewLogs",
      render: (item, record) => (
        <Button
          disabled={!record?.setupCompleted}
          onClick={() => handleNavigation(record)}
          type={record?.setupCompleted ? "primary" : "default"}
          size="small"
        >
          View
        </Button>
      ),
    },
  ];

  return (
    <Spin style={{ zIndex: 1 }} spinning={loading}>
      <UpdateOrganizationModal
        visible={updtaeOrg || extendTrial}
        typeOptions={industryTypes}
        onCancel={() => {
          setUpdateOrg(false);
          setExtendTrial(false);
        }}
        extendTrial={extendTrial}
        messageApi={messageApi}
        setLoading={setLoading}
      />
      <div style={styles.container}>
        <Card style={styles.headerCard}>
          <Row gutter={[16, 16]}>
            <Col xs={24} md={16}>
              <Title level={4} style={{ textAlign: "left" }}>
                <ArrowLeftOutlined
                  style={{
                    ...styles.arrowStyle,
                    ...(isHovered && styles.arrowHoverStyle),
                  }}
                  onClick={() => navigate("/app/dashboard")}
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}
                />
                {organization?.name} Summary
              </Title>
              <Row gutter={[16, 16]}>
                <Col xs={24} sm={8}>
                  <Card style={styles.card} bordered>
                    <p style={{ fontSize: "14px", margin: 0 }}>
                      {organization?.type?.name}
                    </p>
                  </Card>
                </Col>
                <Col xs={24} sm={8}>
                  <Card style={styles.card} bordered>
                    <p style={{ fontSize: "14px", margin: 0 }}>
                      {organization?.driverCount || 0} Drivers
                    </p>
                  </Card>
                </Col>
                <Col xs={24} sm={8}>
                  <Card style={styles.card} bordered>
                    <p style={{ fontSize: "14px", margin: 0 }}>
                      {organization?.adminOperatorCount || 0} Admins
                    </p>
                  </Card>
                </Col>
              </Row>
            </Col>
            <Col xs={24} md={8} style={styles.billingSection}>
              <Title level={5}>Billing Plan</Title>
              <h1
                style={{
                  fontSize: "14px",
                  color: moment(organization?.subscriptionExpiredAt).isAfter(
                    moment()
                  )
                    ? "#4CBB17"
                    : "#FF6347",
                }}
              >
                {organization?.subscriptionExpiredAt
                  ? moment(organization?.subscriptionExpiredAt).format("LLL")
                  : "N/A"}
              </h1>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "10px",
                  marginTop: "10px",
                  justifyContent: "flex-end",
                }}
              >
                <Button type="primary" onClick={HandleExtendTrial}>
                  Extend Trial
                </Button>
                <Button type="primary" onClick={modifyOrganization}>
                  Modify
                </Button>
              </div>
            </Col>
          </Row>
        </Card>

        <div style={styles.tableContainer}>
          <div style={styles.header}>
            <Title level={4} style={{ marginBottom: "16px" }}>
              On-Duty Drivers
            </Title>

            <div style={styles.searchContainer}>
              <Input
                placeholder="Search by driver name..."
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                style={styles.input}
              />
              <Button
                type="primary"
                onClick={handleSearch}
                // disabled={searchText.length == 0}
              >
                Search
              </Button>
              <Button
                type="secondary"
                disabled={searchText.length == 0}
                onClick={() => {
                  setSearchText("");
                  loadDashboard();
                }}
              >
                Clear
              </Button>
            </div>
          </div>
          {contextHolder}
          <Table
            dataSource={[...drivers?.data]}
            columns={columns}
            pagination={paginationConfig}
            bordered
            scroll={{ x: "max-content" }}
          />
        </div>
      </div>
    </Spin>
  );
};

export default OrganizationDetailsScreen;
