import { Button, Card, Input, Table, Typography, Spin, message } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAdminDashboard,
  fetchOrganizationDashboard,
} from "../store/reducers/adminReducer";
import { useNavigate } from "react-router-dom";
import UpdateOrganizationModal from "./UpdateOrganizationModal";
import { getOperatorIndustryType } from "../helpers/authAPI";
const { Title } = Typography;

const styles = {
  container: { padding: "24px",minHeight: "100vh" },
  summaryContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))",
    gap: "16px",
    marginBottom: "24px",
  },
  card: {
    borderRadius: "12px",
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "120px",
    boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
    backgroundColor: "#fff",
  },
  cardHover: {
    transform: "translateY(-5px)",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    alignItems: "center",
    marginBottom: "16px",
  },
  searchContainer: {
    display: "flex",
    gap: "12px",
    flexWrap: "wrap",
  },
  input: {
    flex: 1,
    // width: "300px",
    minWidth: "250px",
    borderRadius: "6px",
  },
  tableContainer: {
    background: "#fff",
    borderRadius: "12px",
    padding: "20px",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
  },
  actionButton: {
    fontSize: "14px",
    borderRadius: "6px",
  },
  industryCell: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: "12px",
  },
};

const Dashboard = () => {
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(false);
  const access_token = useSelector((state) => state?.persist?.auth.token);
  const { adminDashboard } = useSelector((state) => state?.persist?.admin);
  const organizations = adminDashboard?.organization?.data || [];
  const pagination = adminDashboard?.organization?.pagination || {};
  const [filteredData, setFilteredData] = useState(organizations);
  const [messageApi, contextHolder] = message.useMessage();
  const [updateIndustryType, setUpdateIndutryType] = useState(false);
  const [industryTypes, setIndustryTypes] = useState([]);
  const [orgData, setOrgData] = useState();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const columns = [
    {
      title: <span style={{ fontWeight: "bold" }}>Organization</span>,
      dataIndex: "name",
      key: "organization",
      sorter: (a, b) => a?.name.localeCompare(b?.name),
    },
    {
      title: <span style={{ fontWeight: "bold" }}>Industry Type</span>,
      dataIndex: "type",
      key: "industry",
      sorter: (a, b) => a?.type.name.localeCompare(b?.type.name),
      render: (text, record) => (
        <div style={styles.industryCell}>
          <span>{text?.name}</span>
          <Button
            size="small"
            type="primary"
            onClick={() => handleChangeIndustryType(record)}
          >
            Change
          </Button>
        </div>
      ),
    },
    {
      title: <span style={{ fontWeight: "bold" }}>Rules</span>,
      dataIndex: "rule",
      key: "rule",
      sorter: (a, b) => {
        const hoursA = a?.rule?.max_continous_work_in_a_day?.hours || 0;
        const minutesA = a?.rule?.max_continous_work_in_a_day?.minutes || 0;
        const hoursB = b?.rule?.max_continous_work_in_a_day?.hours || 0;
        const minutesB = b?.rule?.max_continous_work_in_a_day?.minutes || 0;

        if (hoursA !== hoursB) {
          return hoursA - hoursB; // Compare hours first
        }
        return minutesA - minutesB; // If hours are the same, compare minutes
      },
      render: (data) =>
        `${data?.max_continous_work_in_a_day.hours} hrs max + ${data?.max_continous_work_in_a_day.minutes} mins rest`,
    },
    {
      title: <span style={{ fontWeight: "bold" }}>Drivers</span>,
      dataIndex: "driverCount",
      key: "drivers",
      align: "center",
      sorter: (a, b) => a?.driverCount - b?.driverCount,
    },
    {
      title: <span style={{ fontWeight: "bold" }}>Plan Expiry</span>,
      dataIndex: "subscriptionExpiredAt",
      key: "planExpiry",
      sorter: (a, b) => {
        return (
          moment(a?.subscriptionExpiredAt).valueOf() -
          moment(b?.subscriptionExpiredAt).valueOf()
        );
      },
      render: (timestamp) => {
        const expiryDate = moment(timestamp);
        return (
          <span
            style={{ color: expiryDate.isBefore(moment()) ? "red" : "#0fbd20" }}
          >
            {timestamp ? expiryDate.format("LLL") : "N/A"}
          </span>
        );
      },
    },
    {
      title: <span style={{ fontWeight: "bold" }}>Action</span>,
      dataIndex: "id",
      key: "id",
      render: (item) => (
        <div style={styles.actionContainer}>
          <Button
            size="small"
            type="primary"
            onClick={() => handleNavigation(item)}
          >
            View
          </Button>
        </div>
      ),
    },
  ];

  const handleNavigation = (item) => {
    setLoading(true);
    dispatch(
      fetchOrganizationDashboard({ token: access_token, organization_id: item })
    )
      .then((res) => {
        if (res?.payload?.data?.success) navigate(`/app/dashboard/${item}`);
        else if (res?.payload?.message)
          messageApi.open({
            type: "error",
            content:
              res?.payload?.response?.data?.error ||
              res?.payload?.request?.data?.error ||
              res?.payload?.message,
            className: "custom-class",
            style: {
              marginTop: "10vh",
            },
          });
      })
      .catch((error) => {
        messageApi.open({
          type: "error",
          content: "Error fetching dashboard.",
          className: "custom-class",
          style: {
            marginTop: "10vh",
          },
        });
        console.error("Error fetching dashboard:", error);
      })
      .finally(() => setLoading(false));
  };

  const handleSearch = () => {
    const value = searchText.toLowerCase();
    if (!value) {
      messageApi.open({
        type: "error",
        content: "Please enter the text ",
        className: "custom-class",
        style: {
          marginTop: "10vh",
        },
      });
      return;
    }
    setLoading(true);
    dispatch(
      fetchAdminDashboard({
        token: access_token,
        records_per_page: 10,
        search: value,
      })
    )
      .then((res) => {
        if (res?.payload?.message)
          messageApi.open({
            type: "error",
            content:
              res?.payload?.response?.data?.error ||
              res?.payload?.request?.data?.error ||
              res?.payload?.message,
            className: "custom-class",
            style: {
              marginTop: "10vh",
            },
          });
      })
      .catch((error) => {
        messageApi.open({
          type: "error",
          content: "Search failed. Please try again.",
          className: "custom-class",
          style: {
            marginTop: "10vh",
          },
        });
        console.error("Error fetching dashboard:", error);
      })
      .finally(() => setLoading(false));
  };

  const loadDashboard = (page = 1, recordsPerPage = 10) => {
    setLoading(true);
    dispatch(
      fetchAdminDashboard({
        token: access_token,
        page,
        records_per_page: recordsPerPage,
      })
    )
      .then((res) => {
        if (res?.payload?.message)
          messageApi.open({
            type: "error",
            content:
              res?.payload?.response?.data?.error ||
              res?.payload?.request?.data?.error ||
              res?.payload?.message,
            className: "custom-class",
            style: {
              marginTop: "10vh",
            },
          });
      })
      .catch((error) => {
        messageApi.open({
          type: "error",
          content: "Failed to load dashboard.",
          className: "custom-class",
          style: {
            marginTop: "10vh",
          },
        });
        console.error("Failed to load dashboard", error);
      })
      .finally(() => setLoading(false));
  };

  const paginationConfig = {
    current: pagination?.current_page,
    pageSize: pagination?.records_per_page || 10,
    total: pagination?.num_pages * (pagination?.records_per_page || 10),
    showSizeChanger: true,
    pageSizeOptions: ["10", "20", "50", "100"],
    onShowSizeChange: (current, size) => {
      loadDashboard(current, size);
    },
    onChange: (page, pageSize) => {
      loadDashboard(page, pageSize);
    },
  };

  const handleChangeIndustryType = async (record) => {
    try {
      setLoading(true);
      setOrgData(record);
      if (industryTypes?.length == 0) {
        const result = await getOperatorIndustryType();
        setIndustryTypes(result?.data);
        if (result?.success) {
          setUpdateIndutryType(true);
        }
      } else setUpdateIndutryType(true);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setFilteredData(organizations);
  }, [organizations]);

  useEffect(() => {
    loadDashboard();
  }, []);

  return (
    <div style={styles.container}>
      <UpdateOrganizationModal
        visible={updateIndustryType}
        item={orgData}
        updateIndustryType={updateIndustryType}
        typeOptions={industryTypes}
        onCancel={() => setUpdateIndutryType(false)}
        messageApi={messageApi}
        setLoading={setLoading}
      />
      <Title level={2} style={{ marginBottom: "14px", textAlign: "left" }}>
        Dashboard
      </Title>

      <div style={styles.summaryContainer}>
        {[
          { label: "Organizations", value: adminDashboard?.operatorCount },
          { label: "Drivers", value: adminDashboard?.driverCount },
          { label: "Vehicles", value: adminDashboard?.vehicleCount },
        ].map((item, index) => (
          <Card key={index} style={styles.card} bordered>
            <p style={{ fontSize: "24px", margin: 0, fontWeight: "bold" }}>
              {item.value}
            </p>
            <p style={{ fontSize: "16px", margin: 0 }}>{item.label}</p>
          </Card>
        ))}
      </div>

      <div style={styles.tableContainer}>
        <div style={styles.header}>
          <Title level={4} style={{ marginBottom: "16px" }}>
            Organization Summary
          </Title>
          <div style={styles.searchContainer}>
            <Input
              placeholder="Search by organization name..."
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              style={styles.input}
            />
            <Button type="primary" onClick={handleSearch}>
              Search
            </Button>
            <Button
              type="secondary"
              disabled={!searchText}
              onClick={() => {
                setSearchText("");
                loadDashboard();
              }}
            >
              Clear
            </Button>
          </div>
        </div>
        {contextHolder}
        <Spin spinning={loading}>
          <Table
            columns={columns}
            dataSource={filteredData}
            pagination={paginationConfig}
            bordered
            scroll={{ x: "max-content" }}
          />
        </Spin>
      </div>
    </div>
  );
};

export default Dashboard;
