// import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Form,
  Input,
  Row,
  Col,
  Typography,
  Alert,
  Spin,
  message,
  Divider,
  Card,
} from "antd";
import { authentication, requestForToken } from "../config/firebaseConfig";
import * as firebase from "firebase/auth";
// import { signInWithEmailAndPassword } from "firebase/auth";
import { connect, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import {
  checkExistingUser,
  driverLoginToApp,
} from "../store/actions/authActions";
import {
  signInUser,
  updateAuthStatus,
  getNotifications,
} from "../store/reducers/authReducer";
import { jwtDecode } from "jwt-decode";
import { GoogleLogin } from "@react-oauth/google";
import OperatorOnboardingModal from "./OperatorOnboardingModal";
import { getOperatorIndustryType } from "../helpers/authAPI";
import { opratorProfile } from "../store/reducers/operatorReducer";
const { Text, Link, Title } = Typography;

const layout = {
  labelCol: {
    span: 9,
  },
  wrapperCol: {
    span: 6,
  },
};

const validateMessages = {
  required: "${label} is required!",
  types: {
    email: "${label} is not a valid email!",
    number: "${label} is not a valid number!",
  },
  number: {
    range: "${label} must be between ${min} and ${max}",
  },
};

function Login(props) {
  const dispatch = useDispatch();
  const [onboardingModal, setOnboardingModal] = useState(false);
  const [userData, setUserData] = useState({});
  const [industryType, setIndustryType] = useState([]);

  const styles = {
    text_input: {
      textAlign: "left",
    },
  };

  // const [loading, setLoading] = useState(false);
  const navigate = useNavigate(); //

  const callback = (data, token) => {
    // setLoader(false);
    if (data) {
      const firebasedata = {
        token_id: token,
      };
      props.SignIn(firebasedata);
      navigate("/app/dashboard");
    } else {
    }
  };

  const onclicksignup = () => {
    navigate("/signup");
  };
  const onclickforgotpass = () => {
    navigate("/forgotpassword");
  };
  const [loadingSpin, setLoadingSpin] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const onFinish = (values) => {
    setLoadingSpin(true);
    let errormsg = document.getElementById("Errormsg");
    console.log("Success:", values?.email, values?.password);
    firebase
      .signInWithEmailAndPassword(
        authentication,
        values?.email,
        values?.password
      )
      .then((res) => {
        console.log("firebase access token", res.user.accessToken);
        res.user
          .getIdToken()
          .then((token) => {
            props.ExistingUser(values?.email, (res) => {
              if (res?.user_exists) {
                if (res?.isOperator) {
                  dispatch(signInUser(token)).then((result) => {
                    // sessionStorage.setItem("token", result?.payload?.data?.token)

                    if (result?.payload?.data?.success) {
                      requestForToken().then(async (MfcToken) => {
                        const access_token = result?.payload?.data?.token;
                        const payload = { MfcToken, access_token };
                        await dispatch(getNotifications(payload));
                        await dispatch(opratorProfile(access_token));
                      });
                      dispatch(
                        updateAuthStatus({
                          is_authenticated: true,
                          isSuperAdmin: result?.payload?.data?.isSuperAdmin,
                        })
                      );
                      setLoadingSpin(false);
                      navigate("/app/dashboard");
                    }
                    if (result?.payload?.data?.success == false) {
                      setLoadingSpin(false);
                      messageApi.open({
                        type: "warning",
                        content: result?.payload?.data?.message,
                        className: "custom-class",
                        style: {
                          marginTop: "10vh",
                        },
                      });
                    }
                  });
                } else if (!res?.isOperator) {
                  setLoadingSpin(false);
                  if (res?.authProvider !== "google.com") {
                    messageApi.open({
                      type: "error",
                      content:
                        "Account with this email already exists in eLog App, Please use same password to register here.",
                      className: "custom-class",
                      style: {
                        marginTop: "10vh",
                      },
                    });
                  } else {
                    messageApi.open({
                      type: "error",
                      content:
                        "Account with this email already exists in eLog App, Please use register/login with googl to register here.",
                      className: "custom-class",
                      style: {
                        marginTop: "10vh",
                      },
                    });
                  }
                }
              } else {
                setLoadingSpin(false);
                messageApi.open({
                  type: "error",
                  content:
                    "The operator associated with this email does not exist.",
                  className: "custom-class",
                  style: {
                    marginTop: "10vh",
                  },
                });
              }
            });
          })
          .catch((err) => {
            messageApi.open({
              type: "error",
              content: err,
              className: "custom-class",
              style: {
                marginTop: "10vh",
              },
            });
            console.error("failed to get IdToken");
          });

        // checking for existing user should happen when email is
        // already typed! Not before calling our sign-in/create account API
        // props.ExistingUser(values.email, callback, res.user.accessToken);
      })
      .catch((err) => {
        setLoadingSpin(false);
        errormsg.innerText = "Incorrect username or password";
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onSubmit = (event) => {
    event.preventDefault();
  };

  const loginWithGoogle = (credential) => {
    setLoadingSpin(true);
    console.log(credential);
    const token = credential;
    const user = jwtDecode(token);
    const googleCredential = firebase.GoogleAuthProvider.credential(credential);
    firebase
      .signInWithCredential(authentication, googleCredential)
      .then((res) => {
        const access_token = res?.user?.accessToken;
        props.ExistingUser(user?.email, (res) => {
          if (!(res?.user_exists && res?.isOperator)) {
            setUserData({
              firstname: user?.given_name,
              lastname: user?.family_name,
              phoneNumber: user?.phone_number ? user?.phone_number : "",
              operatorName: user?.business_name ? user?.business_name : "",
              token_id: access_token,
              email: user?.email,
              displayName: user?.name,
              authProvider: res?.providerId,
            });
            setOnboardingModal(true);
            setLoadingSpin(false);
          } else {
            dispatch(signInUser(access_token)).then((result) => {
              if (result?.payload?.data?.success) {
                requestForToken().then((MfcToken) => {
                  const access_token = result?.payload?.data?.token;
                  const payload = { MfcToken, access_token };
                  dispatch(getNotifications(payload));
                });
                dispatch(
                  updateAuthStatus({
                    is_authenticated: true,
                    isSuperAdmin: result?.payload?.data?.isSuperAdmin,
                  })
                );
                setLoadingSpin(false);
                navigate("/app/dashboard");
              } else if (result?.payload) {
                const { message } = JSON.parse(result?.payload);
                setLoadingSpin(false);
                messageApi.open({
                  type: "warning",
                  content: message,
                  className: "custom-class",
                  style: {
                    marginTop: "10vh",
                  },
                });
              }
            });
          }
        });
      })
      .catch((err) => {
        setLoadingSpin(false);
        messageApi.open({
          type: "error",
          content: err,
          className: "custom-class",
          style: {
            marginTop: "10vh",
          },
        });
        console.error("failed to get IdToken");
      });
  };

  const success = () => {
    messageApi.open({
      type: "warning",
      content: "This is a prompt message with custom className and style",
      className: "custom-class",
      style: {
        marginTop: "10vh",
      },
    });
  };

  useEffect(() => {
    const uniqueKey = "fetch-error"; // Unique key for the message
    (async () => {
      try {
        const result = await getOperatorIndustryType();
        if (result?.success) {
          const defaultData = result?.data;
          const updatedOptions = defaultData?.map((item) => ({
            value: item?.id,
            label: item?.name,
          }));
          setIndustryType(updatedOptions);
        }
      } catch (error) {
        messageApi.open({
          key: uniqueKey,
          type: "error",
          content: error?.message || "Something went wrong",
          className: "custom-class",
          style: {
            marginTop: "10vh",
          },
        });
        console.error("Error fetching operator industry type:", error);
      }
    })();
  }, []);

  return (
    <Spin spinning={loadingSpin} size="large">
      <img
        src="https://www.elogbook.co.nz/wp-content/uploads/2023/03/eLogbook-logo-transperant.png"
        width={"10%"}
      />
      {contextHolder}
      <OperatorOnboardingModal
        visible={onboardingModal}
        data={userData}
        industryType={industryType}
        onClose={() => {
          setUserData({});
          setOnboardingModal(false);
        }}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Card
          style={{
            width: "90%",
            maxWidth: "30%",
            padding: 20,
            borderRadius: 10,
            boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
            background: "#fff",
            height: "auto",
          }}
          title={
            <span
              style={{
                justifyContent: "flex-start",
                display: "flex",
                fontSize: "20px",
              }}
            >
              L O G I N
            </span>
          }
        >
          <Form
            onSubmit={onSubmit}
            validateMessages={validateMessages}
            name="basic"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            style={{ width: "100%" }}
          >
            <div style={{ marginTop: 10 }}>
              <Form.Item
                style={{ textAlign: "left" }}
                name="email"
                rules={[
                  {
                    required: true,
                  },
                  {
                    type: "email",
                    message: "Enter the valid email",
                  },
                ]}
              >
                <Input
                  placeholder="Email"
                  size="large"
                  style={{
                    borderRadius: 10,
                    height: 40,
                    fontSize: 15,
                  }}
                />
              </Form.Item>

              <Form.Item
                name="password"
                style={{ textAlign: "left" }}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input.Password
                  placeholder="Password"
                  size="large"
                  style={{
                    borderRadius: 10,
                    height: 40,
                    fontSize: 15,
                  }}
                />
              </Form.Item>
              <div
                style={{
                  display: "flex",
                }}
              >
                <Link onClick={onclickforgotpass}>FORGET PASSWORD</Link>
              </div>
            </div>

            <p
              id="Errormsg"
              style={{ color: "red", textAlign: "center", fontSize: 10 }}
            ></p>
            <Form.Item>
              <div block style={{ justifyContent: "center", display: "flex" }}>
                <Button
                  type="primary"
                  size="large"
                  htmlType="submit"
                  block
                  style={{
                    borderRadius: 5,
                    width: "60%",
                    height: 40,
                  }}
                >
                  L O G I N
                </Button>
              </div>

              <Divider>or</Divider>

              <Form.Item>
                <div style={{ justifyContent: "center", display: "flex" }}>
                  <GoogleLogin
                    width={"400"}
                    theme="outline"
                    logo_alignment="center"
                    onSuccess={(credentialResponse) => {
                      loginWithGoogle(credentialResponse?.credential);
                    }}
                    onError={() => {
                      console.log("Login Failed");
                    }}
                  />
                </div>
              </Form.Item>
            </Form.Item>
          </Form>

          {/* <br /> */}
          <Text direction="horizontal" style={{ textAlign: "center" }}>
            Don't have an account? <Link onClick={onclicksignup}>SIGN UP</Link>
          </Text>
        </Card>
      </div>
    </Spin>
  );
}

const mapStateToProps = (state) => {
  return {
    firebasetoken: state.authReducer?.firebase_token,
    isUserExists: state.authReducer?.isUserExists,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    ExistingUser: (payload, callback, token) =>
      dispatch(checkExistingUser(payload, callback, token)),
    // SignIn: (payload, callback) =>
    //   dispatch(driverLoginToApp(payload, callback)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Login);
