import { Col, DatePicker, Form, Input, Modal, Row, Select, Spin } from "antd";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";

import { useDispatch, useSelector } from "react-redux";
import {
  fetchDriverDashboard,
  fetchOrganizationDashboard,
  updateDriverByAdmin,
} from "../store/reducers/adminReducer";

const driverType = [
  { id: 1, name: "Heavy Vehicle Driver" },
  { id: 2, name: "Small Passenger Vehicle Driver" },
];

const UpdateDriverModal = ({
  visible = true,
  onCancel,
  organization_id,
  messageApi,
}) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [phoneValue, setPhoneValue] = useState("");

  const { driver } = useSelector(
    (state) => state?.persist?.admin?.driverDashboard
  );
  const access_token = useSelector((state) => state?.persist?.auth.token);

  const initialValues = useMemo(() => {
    const nameParts = driver?.name?.split(" ") || ["", ""];
    return driver
      ? {
          firstname: nameParts[0],
          lastname: nameParts[1],
          phoneNumber: driver.phoneNumber,
          dateOfBirth: driver.dateOfBirth
            ? moment(driver.dateOfBirth, "DD-MM-YYYY")
            : null,
          licenseNumber: driver.licenseNumber,
          type: driver.driverType?.id,
        }
      : {};
  }, [driver]);

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [initialValues, form, visible]);

  const handleOk = async () => {
    try {
      const values = await form.validateFields();

      // Filter updated values
      const updatedValues = Object.fromEntries(
        Object.entries(values).filter(
          ([key, value]) => initialValues[key] !== value
        )
      );

      // If no changes, just close modal
      if (!Object.keys(updatedValues).length) return onCancel();

      // Format date if changed
      if (updatedValues.dateOfBirth) {
        updatedValues.dateOfBirth = moment(updatedValues.dateOfBirth).format(
          "YYYY-MM-DD"
        );
      }

      setLoading(true);

      const payload = {
        organization_id,
        driver_id: driver?.id,
        token: access_token,
        driverData: updatedValues,
      };

      const res = await dispatch(updateDriverByAdmin(payload));

      if (res?.payload?.message) {
        throw new Error(
          res?.payload?.response?.data?.message ||
            res?.payload?.request?.data?.message ||
            res?.payload?.message ||
            "Unexpected error occurred"
        );
      }

      if (res?.payload?.data?.success) {
        await Promise.all([
          dispatch(
            fetchOrganizationDashboard({ token: access_token, organization_id })
          ),
          dispatch(
            fetchDriverDashboard({
              token: access_token,
              driver_id: driver?.id,
              organization_id,
              fromDate: moment().subtract(7, "days").format("YYYY-MM-DD"),
              toDate: moment().format("YYYY-MM-DD"),
            })
          ),
        ]);

        messageApi.open({
          type: "success",
          content: res?.payload?.data?.message,
          className: "custom-class",
          style: { marginTop: "10vh" },
        });

        onCancel();
      }
    } catch (error) {
      messageApi.open({
        type: "error",
        content: error.message || "Unexpected error occurred",
        className: "custom-class",
        style: { marginTop: "10vh" },
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      title={`Update ${driver?.name}`}
      open={visible}
      onCancel={onCancel}
      onOk={handleOk}
      okText="Submit"
      cancelText="Cancel"
    >
      <Spin spinning={loading}>
        <Form form={form} layout="vertical">
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="First Name"
                name="firstname"
                rules={[{ required: true, message: "Please enter first name" }]}
              >
                <Input placeholder="Enter first name" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Last Name"
                name="lastname"
                rules={[{ required: true, message: "Please enter last name" }]}
              >
                <Input placeholder="Enter last name" />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item
            label="Phone Number"
            name="phoneNumber"
            rules={[{ required: true, message: "Please enter a phone number" }]}
          >
            <PhoneInput
              placeholder="Enter phone number"
              style={{ textOverflow: "ellipsis", display: "flex" }}
              defaultCountry="NZ" // Set default country
              value={phoneValue}
              onChange={setPhoneValue}
              className="ant-input"
            />
          </Form.Item>

          <Form.Item
            label="Date of Birth"
            name="dateOfBirth"
            rules={[{ required: true, message: "Please select date of birth" }]}
          >
            <DatePicker
              format="DD-MM-YYYY"
              style={{ width: "100%" }}
              disabledDate={(current) => {
                return current && current > moment().subtract(18, "years");
              }}
            />
          </Form.Item>

          <Form.Item
            label="License Number"
            name="licenseNumber"
            rules={[{ required: true, message: "Please enter license number" }]}
          >
            <Input
              placeholder="Enter license number"
              style={{ width: "100%" }}
            />
          </Form.Item>

          <Form.Item
            label="Type"
            name="type"
            rules={[{ required: true, message: "Please select a type" }]}
          >
            <Select placeholder="Select type" style={{ width: "100%" }}>
              {driverType?.map((type) => (
                <Select.Option key={type.id} value={type.id}>
                  {type.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};

export default UpdateDriverModal;
