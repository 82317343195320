import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./reducers/authReducer";
import vehicleReducer from "./reducers/vehicleReducer";
import driverReducer from "./reducers/driverReducer";
import operatorReducer from "./reducers/operatorReducer";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import { combineReducers } from "@reduxjs/toolkit";
import paymentReducer from "./reducers/paymentReducer";
import adminReducer from "./reducers/adminReducer";

// persist config guide https://blog.reactnativecoach.com/the-definitive-guide-to-redux-persist-84738167975
const persistConfig = {
  key: "root",
  version: 1,
  storage,
};

const appReducer = combineReducers({
  auth: authReducer,
  vehicle: vehicleReducer,
  driver: driverReducer,
  operator: operatorReducer,
  payment: paymentReducer,
  admin: adminReducer,
});

const rootReducer = (state, action) => {
  if (action.type === "LOGOUT") {
    // Reset state by returning undefined, triggering reducers to use their initial state
    state = undefined;
  }
  return appReducer(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export default configureStore({
  reducer: {
    persist: persistedReducer,
  },
});

// thunk is already inside the toolkit
