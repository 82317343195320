import { DatePicker, Form, Input, Modal, Select, Spin } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAdminDashboard,
  fetchOrganizationDashboard,
  updateOrganizationByAdmin,
} from "../store/reducers/adminReducer";

const UpdateOrganizationModal = ({
  visible = true,
  onCancel,
  extendTrial,
  updateIndustryType,
  typeOptions = [],
  messageApi,
  item,
}) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const organization = useSelector(
    (state) => state?.persist?.admin.organizationDashboard?.organization
  );
  const access_token = useSelector((state) => state?.persist?.auth.token);

  const initialValues = {
    name: item?.name || organization?.name,
    type: item?.type?.id || organization?.type?.id,
    planExpiredAt: item?.subscriptionExpiredAt
      ? moment(item.subscriptionExpiredAt)
      : organization?.subscriptionExpiredAt
      ? moment(organization.subscriptionExpiredAt)
      : null,
  };

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [item, organization, form, visible]);

  const handleOk = async () => {
    try {
      const values = await form.validateFields();
      const updatedValues = Object.keys(values).reduce((acc, key) => {
        if (initialValues[key] !== values[key]) {
          acc[key] = values[key];
        }
        return acc;
      }, {});

      if (!Object.keys(updatedValues).length) {
        onCancel();
        return;
      }

      setLoading(true);

      if (updatedValues.planExpiredAt) {
        updatedValues.planExpiredAt = updatedValues.planExpiredAt
          .endOf("day")
          .unix(); // Convert to epoch
      }

      const payload = {
        organization_id: item?.id || organization?.id,
        token: access_token,
        organizationData: updatedValues,
      };

      const res = await dispatch(updateOrganizationByAdmin(payload)).unwrap();

      if (res?.data?.success) {
        if (item?.id)
          await dispatch(
            fetchAdminDashboard({
              token: access_token,
            })
          );
        else
          await dispatch(
            fetchOrganizationDashboard({
              token: access_token,
              organization_id: item?.id || organization?.id,
            })
          );

        messageApi.open({
          type: "success",
          content: res?.data?.message || "Organization updated successfully!",
          className: "custom-class",
          style: { marginTop: "10vh" },
        });

        onCancel();
      } else {
        throw new Error(res?.response?.data?.message || res?.message);
      }
    } catch (error) {
      messageApi.open({
        type: "error",
        content: error?.message || "Unexpected error occurred",
        className: "custom-class",
        style: { marginTop: "10vh" },
      });
      console.error("Update Failed:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      title={`Update Organization - ${item?.name || organization?.name}`}
      open={visible}
      onCancel={onCancel}
      onOk={handleOk}
      okText="Submit"
      cancelText="Cancel"
    >
      <Spin spinning={loading}>
        <Form form={form} layout="vertical">
          {!updateIndustryType && (
            <Form.Item
              label="Organization Name"
              name="name"
              rules={[{ required: true, message: "Please enter a name" }]}
            >
              <Input
                disabled={extendTrial}
                placeholder="Enter name"
                style={{ width: "100%" }}
              />
            </Form.Item>
          )}
          <Form.Item
            label="Industry Type"
            name="type"
            rules={[{ required: true, message: "Please select a type" }]}
          >
            <Select
              disabled={extendTrial}
              placeholder="Select type"
              style={{ width: "100%" }}
            >
              {typeOptions.map((type) => (
                <Select.Option key={type.id} value={type.id}>
                  {type.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          {!updateIndustryType && (
            <Form.Item
              label="Set Plan Expired Date"
              name="planExpiredAt"
              rules={[{ required: true, message: "Please select a date" }]}
            >
              <DatePicker format="DD-MM-YYYY" style={{ width: "100%" }} />
            </Form.Item>
          )}
        </Form>
      </Spin>
    </Modal>
  );
};

export default UpdateOrganizationModal;
