import API from "../store/utils/APIUtils";

export const fetchAdminDashboardHandler = async (payload) => {
  const { token, search, page = 1, records_per_page = 10 } = payload;
  const params = new URLSearchParams();

  if (search) params.append("search", search);
  if (page) params.append("page", page);
  if (records_per_page) params.append("records_per_page", records_per_page);

  const response = await API.get(
    `/api/v1/admin/dashboard/?${params.toString()}`,
    {
      headers: {
        Authorization: `token ${token}`,
        "Content-Type": "application/json",
      },
    }
  );

  return response;
};

export const fetchOrganizationDashboardHandler = async (payload) => {
  const {
    token,
    search,
    page = 1,
    records_per_page = 10,
    organization_id,
  } = payload;
  const params = new URLSearchParams();

  if (search) params.append("search", search);
  if (page) params.append("page", page);
  if (records_per_page) params.append("records_per_page", records_per_page);

  const response = await API.get(
    `/api/v1/admin/organization/${organization_id}/dashboard/?${params.toString()}`,
    {
      headers: {
        Authorization: `token ${token}`,
        "Content-Type": "application/json",
      },
    }
  );

  return response;
};

export const fetchDriverDashboardHandler = async (payload) => {
  const {
    token,
    search,
    page = 1,
    records_per_page = 10,
    driver_id,
    organization_id,
    fromDate,
    toDate,
  } = payload;

  const params = new URLSearchParams();

  if (search) params.append("search", search);
  if (page) params.append("page", page);
  if (records_per_page) params.append("records_per_page", records_per_page);
  if (fromDate && toDate) {
    params.append("fromDate", fromDate);
    params.append("toDate", toDate);
  }

  const response = await API.get(
    `/api/v1/admin/organization/${organization_id}/driver/${driver_id}/dashboard/?${params.toString()}`,
    {
      headers: {
        Authorization: `token ${token}`,
        "Content-Type": "application/json",
      },
    }
  );

  return response;
};

export const updateOrganizationHandler = async (payload) => {
  const { token, organization_id, organizationData } = payload;
  const response = await API.put(
    `/api/v1/admin/update/organization/${organization_id}/`,
    JSON.stringify(organizationData),
    {
      headers: {
        Authorization: `token ${token}`,
        "Content-Type": "application/json",
      },
    }
  );

  return response;
};

export const updateDriverHandler = async (payload) => {
  const { token, organization_id, driver_id, driverData } = payload;

  const response = await API.put(
    `/api/v1/admin/organization/${organization_id}/update/driver/${driver_id}/`,
    JSON.stringify(driverData),
    {
      headers: {
        Authorization: `token ${token}`,
        "Content-Type": "application/json",
      },
    }
  );

  return response;
};
