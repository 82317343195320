import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  fetchAdminDashboardHandler,
  fetchDriverDashboardHandler,
  fetchOrganizationDashboardHandler,
  updateDriverHandler,
  updateOrganizationHandler,
} from "../../helpers/adminApi";

const initialState = {
  adminDashboard: {},
  organizationDashboard: {},
  driverDashboard: {},
  error: null,
};

export const fetchAdminDashboard = createAsyncThunk(
  "admin/fetchAdminDashboard",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await fetchAdminDashboardHandler(payload);
      return response;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const fetchOrganizationDashboard = createAsyncThunk(
  "admin/fetchOrganizationDashboard",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await fetchOrganizationDashboardHandler(payload);
      return response;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const updateOrganizationByAdmin = createAsyncThunk(
  "admin/updateOrganizationByAdmin",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await updateOrganizationHandler(payload);
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const fetchDriverDashboard = createAsyncThunk(
  "admin/fetchDriverDashboard",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await fetchDriverDashboardHandler(payload);
      return response;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const updateDriverByAdmin = createAsyncThunk(
  "admin/updateDriverByAdmin",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await updateDriverHandler(payload);
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const adminSlice = createSlice({
  name: "admin",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchAdminDashboard.fulfilled, (state, { payload }) => {
        state.adminDashboard = payload?.data?.data;
      })
      .addCase(fetchAdminDashboard.rejected, (state, { payload }) => {
        console.log("Error in API", payload);
      })
      .addCase(fetchOrganizationDashboard.fulfilled, (state, { payload }) => {
        state.organizationDashboard = payload?.data?.data;
      })
      .addCase(fetchOrganizationDashboard.rejected, (state, { payload }) => {
        console.log("Error in API", payload);
      })
      .addCase(fetchDriverDashboard.fulfilled, (state, { payload }) => {
        state.driverDashboard = payload?.data?.data;
      })
      .addCase(fetchDriverDashboard.rejected, (state, { payload }) => {
        console.log("Error in API", payload);
      })
      .addCase(updateOrganizationByAdmin.fulfilled, (state, { payload }) => {
        // state.organizationDashboard = payload?.data?.data;
        console.log("Error in API", payload);
      })
      .addCase(updateOrganizationByAdmin.rejected, (state, { payload }) => {
        console.log("Error in API", payload);
      })
      .addCase(updateDriverByAdmin.fulfilled, (state, { payload }) => {
        // state.organizationDashboard = payload?.data?.data;
        console.log("Error in API", payload);
      })
      .addCase(updateDriverByAdmin.rejected, (state, { payload }) => {
        console.log("Error in API", payload);
      });
  },
});

export default adminSlice.reducer;
