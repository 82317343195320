import { ArrowLeftOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  DatePicker,
  message,
  Row,
  Select,
  Space,
  Spin,
  Table,
  Typography,
} from "antd";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import moment from "moment";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { fetchDriverDashboard } from "../store/reducers/adminReducer";
import UpdateDriverModal from "./UpdateDriverModal";

const { Title } = Typography;

const styles = {
  container: { padding: "24px", minHeight: "100vh" },
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  card: {
    border: "1px solid #d9d9d9",
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "70px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    fontSize: "16px",
    fontWeight: "bold",
  },
  headerCard: {
    marginBottom: "24px",
  },
  tableContainer: {
    marginTop: "20px",
    backgroundColor: "white",
    borderRadius: "8px",
    padding: "20px",
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
  },
  // header: {
  //   display: "flex",
  //   flexDirection: "row",
  //   justifyContent: "space-between",
  //   flexWrap: "wrap",
  // },
  searchContainer: {
    display: "flex",
    gap: "8px",
    marginBottom: "16px",
    flexWrap: "wrap",
  },
  input: {
    flex: 1,
    width: 300,
    minWidth: "200px",
  },
  billingSection: {
    textAlign: "right",
  },
  arrowStyle: {
    width: 60,
    cursor: "pointer",
    transition: "color 0.3s",
  },

  arrowHoverStyle: {
    color: "#1890ff", // Change to your desired hover color
  },
};

const columns = [
  {
    title: (
      <span
        style={{
          fontWeight: "bold",
        }}
      >
        Date
      </span>
    ),
    dataIndex: "createdAt",
    key: "createdAt",
    sorter: (a, b) => moment(a?.createdAt).unix() - moment(b?.createdAt).unix(),
    render: (item) => moment(item).format("LL"),
  },
  {
    title: (
      <span
        style={{
          fontWeight: "bold",
        }}
      >
        Work Remaining In Day (hh:mm)
      </span>
    ),
    dataIndex: "workRemainingInDay",
    key: "workRemainingInDay",
    align: "center",
    sorter: (a, b) =>
      a?.workRemainingInDay?.hours * 60 +
      a?.workRemainingInDay?.minutes -
      (b?.workRemainingInDay?.hours * 60 + b?.workRemainingInDay?.minutes),
    render: (item) =>
      item
        ? `${String(item?.hours).padStart(2, "0")}:${String(
            item?.minutes
          ).padStart(2, "0")}`
        : "00:00",
  },
  {
    title: (
      <span
        style={{
          fontWeight: "bold",
        }}
      >
        Work Done In Day (hh:mm)
      </span>
    ),
    dataIndex: "workDoneInDay",
    key: "workDoneInDay",
    align: "center",
    sorter: (a, b) =>
      a?.workDoneInDay?.hours * 60 +
      a?.workDoneInDay?.minutes -
      (b?.workDoneInDay?.hours * 60 + b?.workDoneInDay?.minutes),
    render: (item) =>
      item
        ? `${String(item?.hours).padStart(2, "0")}:${String(
            item?.minutes
          ).padStart(2, "0")}`
        : "00:00",
  },
  {
    title: (
      <span
        style={{
          fontWeight: "bold",
        }}
      >
        Rest In Day (hh:mm)
      </span>
    ),
    dataIndex: "restInDay",
    key: "restInDay",
    align: "center",
    sorter: (a, b) =>
      a?.restInDay?.hours * 60 +
      a?.restInDay?.minutes -
      (b?.restInDay?.hours * 60 + b?.restInDay?.minutes),
    render: (item) =>
      item
        ? `${String(item?.hours).padStart(2, "0")}:${String(
            item?.minutes
          ).padStart(2, "0")}`
        : "00:00",
  },
  {
    title: (
      <span
        style={{
          fontWeight: "bold",
        }}
      >
        PDF Logs
      </span>
    ),
    dataIndex: "report",
    align: "center",
    key: "report",
    render: (item) => (
      <a target="_blank" rel="noopener noreferrer" href={item}>
        PDF Report
      </a>
    ),
  },
];
const DriverDashboard = () => {
  const { Option } = Select;
  const { RangePicker } = DatePicker;

  const access_token = useSelector((state) => state?.persist?.auth.token);
  const { organizationDashboard } = useSelector(
    (state) => state?.persist?.admin
  );
  const { organization } = organizationDashboard;
  const { driverDashboard } = useSelector((state) => state?.persist?.admin);
  const { driver, cumulativeLogs, weekly_logs } = driverDashboard;
  const pagination = driverDashboard?.pagination || {};
  const [loading, setLoading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [updateDriver, setUpdateDriver] = useState(false);
  const [isHovered, setIsHovered] = React.useState(false);
  const [selectedDays, setSelectedDays] = useState("7");
  const [dateRange, setDateRange] = useState(null);

  const navigate = useNavigate();

  const last7Days = Array.from({ length: 7 }, (_, i) => {
    const date = moment().subtract(i, "days").format("YYYY-MM-DD"); // Use full date for comparison
    const shortDay = moment(date).format("dd"); // Short day name (Su, Mo, etc.)
    return { date, shortDay };
  }).reverse(); // Reverse to show in correct order

  const dispatch = useDispatch();
  const params = useParams();
  const { organization_id, driver_id } = params;

  const handleSearch = () => {
    setLoading(true);
    let fromDate, toDate;
    if (selectedDays === "date-range" && dateRange?.length === 2) {
      fromDate = moment(dateRange[0]).format("YYYY-MM-DD");
      toDate = moment(dateRange[1]).format("YYYY-MM-DD");
    } else {
      fromDate = moment().subtract(selectedDays, "days").format("YYYY-MM-DD");
      toDate = moment().format("YYYY-MM-DD");
    }
    dispatch(
      fetchDriverDashboard({
        token: access_token,
        organization_id,
        driver_id,
        records_per_page: 10,
        fromDate,
        toDate,
      })
    )
      .then((res) => {
        if (res?.payload?.message)
          messageApi.open({
            type: "error",
            content:
              res?.payload?.response?.data?.error ||
              res?.payload?.request?.data?.error ||
              res?.payload?.message,
            className: "custom-class",
            style: {
              marginTop: "10vh",
            },
          });
      })
      .catch((error) => {
        messageApi.open({
          type: "error",
          content: "Failed to load dashboard.",
          className: "custom-class",
          style: {
            marginTop: "10vh",
          },
        });
        console.error("Failed to load dashboard", error);
      })
      .finally(() => setLoading(false));
  };

  const loadDashboard = (page = 1, recordsPerPage = 10) => {
    setLoading(true);
    let fromDate, toDate;
    if (selectedDays === "date-range" && dateRange?.length === 2) {
      fromDate = moment(dateRange[0]).format("YYYY-MM-DD");
      toDate = moment(dateRange[1]).format("YYYY-MM-DD");
    } else {
      fromDate = moment().subtract(selectedDays, "days").format("YYYY-MM-DD");
      toDate = moment().format("YYYY-MM-DD");
    }
    dispatch(
      fetchDriverDashboard({
        token: access_token,
        organization_id,
        driver_id,
        page,
        records_per_page: recordsPerPage,
        fromDate,
        toDate,
      })
    )
      .then((res) => {
        if (res?.payload?.message)
          messageApi.open({
            type: "error",
            content:
              res?.payload?.response?.data?.error ||
              res?.payload?.request?.data?.error ||
              res?.payload?.message,
            className: "custom-class",
            style: {
              marginTop: "10vh",
            },
          });
      })
      .catch((error) => {
        messageApi.open({
          type: "error",
          content: "Failed to load dashboard.",
          className: "custom-class",
          style: {
            marginTop: "10vh",
          },
        });
        console.error("Failed to load dashboard", error);
      })
      .finally(() => setLoading(false));
  };

  const paginationConfig = {
    current: pagination?.current_page,
    pageSize: pagination?.records_per_page || 10,
    total: pagination?.num_pages * (pagination?.records_per_page || 10),
    showSizeChanger: true,
    pageSizeOptions: ["10", "20", "50", "100"],
    onShowSizeChange: (current, size) => {
      loadDashboard(current, size);
    },
    onChange: (page, pageSize) => {
      loadDashboard(page, pageSize);
    },
  };

  const handleExport = () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Cumulative Logs");

    // Define the columns for the Excel sheet
    worksheet.columns = columns.map((col) => ({
      header: col.title,
      key: col.key,
      width: 20,
    }));

    // Add rows to the worksheet
    cumulativeLogs?.data?.forEach((log) => {
      const row = columns.map((col) => {
        if (col.key === "createdAt") {
          return moment(log[col.key]).format("LL");
        } else if (
          col.key === "workRemainingInDay" ||
          col.key === "workDoneInDay" ||
          col.key === "restInDay"
        ) {
          const item = log[col.key];
          return item
            ? `${String(item?.hours).padStart(2, "0")}:${String(
                item?.minutes
              ).padStart(2, "0")}`
            : "00:00";
        } else if (col.key === "report") {
          return log[col.key] || "N/A";
        } else {
          return log[col.key] || "N/A";
        }
      });
      worksheet.addRow(row);
    });

    // Generate the Excel file and save it
    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(blob, `Driver_Logs_${moment().format("YYYY-MM-DD")}.xlsx`);
    });
  };

  return (
    <Spin spinning={loading}>
      <UpdateDriverModal
        visible={updateDriver}
        organization_id={organization_id}
        messageApi={messageApi}
        onCancel={() => setUpdateDriver(false)}
      />
      <div style={styles.container}>
        <Card style={styles.headerCard}>
          <Row gutter={[16, 16]}>
            <Col
              xs={24}
              md={16}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <Title level={4} style={{ textAlign: "left" }}>
                <ArrowLeftOutlined
                  style={{
                    ...styles.arrowStyle,
                    ...(isHovered && styles.arrowHoverStyle),
                  }}
                  onClick={() => navigate(`/app/dashboard/${organization_id}`)}
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}
                />
                {driver?.name} Summary
              </Title>
              <Row gutter={[16, 16]}>
                <Col xs={24} sm={8}>
                  <Card style={styles.card} bordered>
                    <p style={{ fontSize: "14px", margin: 0 }}>
                      {driver?.driverType?.name}
                    </p>
                  </Card>
                </Col>
                <Col xs={24} sm={8}>
                  <Card style={styles.card} bordered>
                    <p style={{ fontSize: "14px", margin: 0 }}>
                      {driver?.selectedDayStart
                        ? moment
                            .unix(driver?.selectedDayStart)
                            .utc()
                            .format("HH:mm") === "00:00"
                          ? "Mid Night (00:00)"
                          : "Noon (12:00)"
                        : "N/A"}
                    </p>
                  </Card>
                </Col>
                <Col xs={24} sm={8}>
                  <Card style={styles.card} bordered>
                    <p style={{ fontSize: "14px", margin: 0 }}>
                      {organization.type.name || "N/A"}
                    </p>
                  </Card>
                </Col>
              </Row>
            </Col>
            <Col xs={24} md={8} style={styles.billingSection}>
              <Title level={5}>Billing Plan</Title>
              <h1
                style={{
                  fontSize: "14px",
                  color: moment(organization?.subscriptionExpiredAt).isAfter(
                    moment()
                  )
                    ? "#4CBB17"
                    : "#FF6347",
                }}
              >
                {moment(organization?.subscriptionExpiredAt).format("LLL")}
              </h1>
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  marginTop: "10px",
                  justifyContent: "flex-end",
                }}
              >
                <Button type="primary" onClick={handleExport}>
                  Export Table
                </Button>
                <Button type="primary" onClick={() => setUpdateDriver(true)}>
                  Modify Driver
                </Button>
              </div>
            </Col>
          </Row>
        </Card>
        <div
          style={{ display: "flex", gap: "8px", justifyContent: "flex-end" }}
        >
          {last7Days?.map(({ date, shortDay }, index) => {
            const hasData = cumulativeLogs?.data?.some(
              (log) => moment(log.createdAt).format("YYYY-MM-DD") === date
            );
            const hasViolation = weekly_logs?.some(
              (log) => log?.date === date && log?.violation
            );

            return (
              <span
                key={index}
                style={{
                  padding: "5px 10px",
                  borderRadius: "50%",
                  backgroundColor: hasViolation
                    ? "red"
                    : hasData
                    ? "#66d575"
                    : "gray",
                  color: hasViolation ? "white" : hasData ? "black" : "white",
                  fontSize: 12,
                  textAlign: "center",
                }}
              >
                {shortDay}
              </span>
            );
          })}
        </div>
        {contextHolder}
        <div style={styles.tableContainer}>
          <div style={styles.header}>
            <Title level={4} style={{ marginBottom: "16px" }}>
              Cumulative Logs
            </Title>

            <div style={styles.searchContainer}>
              <Space direction="horizontal">
                <span style={{ fontWeight: 500 }}>Filter logs by date:</span>
                <Select
                  style={{ width: 150 }}
                  value={selectedDays}
                  onChange={(value) => {
                    setSelectedDays(value);
                    if (value !== "date-range") {
                      setDateRange(null);
                    }
                  }}
                >
                  <Option value="7">Last 7 Days</Option>
                  <Option value="14">Last 14 Days</Option>
                  <Option value="30">Last 30 Days</Option>
                  <Option value="90">Last 90 Days</Option>
                  <Option value="date-range">Select Date Range</Option>
                </Select>
                {selectedDays === "date-range" && (
                  <RangePicker
                    value={dateRange}
                    onChange={(dates) => setDateRange(dates)}
                    disabledDate={(current) =>
                      current && current > moment().endOf("day")
                    }
                  />
                )}
              </Space>
              <Button type="primary" onClick={handleSearch}>
                Search
              </Button>
            </div>
          </div>

          <Table
            dataSource={cumulativeLogs?.data}
            columns={columns}
            pagination={paginationConfig}
            bordered
            scroll={{ x: "max-content" }}
          />
        </div>
      </div>
    </Spin>
  );
};

export default DriverDashboard;
